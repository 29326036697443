'use client';
import React, { useState, useCallback, useEffect } from 'react';
import CategoryDropdown, { Breadcrumb } from './CategoryDropdown';
import { IconChevronRight } from '@tabler/icons-react';
import { ProgrammeWithCategories } from '@dr-pam/common-components/Services/Server/ProgrammeService';
import type { Programme } from '@dr-pam/common-types/database';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export type ProgrammeListProps = {
	programmes: ProgrammeWithCategories[];
	initialProgramme?: Programme;
};

export default function ProgrammeList(props: ProgrammeListProps) {
	const { programmes, initialProgramme } = props;

	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [activeProgrammeId, setActiveProgrammeId] = useState<string | null>(
		initialProgramme ? initialProgramme.id : null,
	);
	const [activeCategories, setActiveCategories] = useState<Record<string, boolean>>({});
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(
		initialProgramme ? [{ id: initialProgramme.id, name: initialProgramme.name }] : [],
	);
	const [activeDepth, setActiveDepth] = useState<number>(0);

	const resetToInitialState = useCallback(() => {
		setActiveProgrammeId(initialProgramme ? initialProgramme.id : null);
		setBreadcrumbs(initialProgramme ? [{ id: initialProgramme.id, name: initialProgramme.name }] : []);
		setActiveCategories({});
		setActiveDepth(0);
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, 50);
	}, [initialProgramme]);

	// This effect resets the state back to the beginning when the user tries to navigate to the top of the page (e.g. by clicking the link in the navbar)
	useEffect(() => {
		if (searchParams.has('top')) {
			router.replace(pathname);
			resetToInitialState();
		}
	}, [pathname, resetToInitialState, router, searchParams]);

	const toggleProgrammeDropdown = useCallback(
		(programmeId: string, programmeName: string) => {
			const isCurrentlyActive = activeProgrammeId === programmeId;
			setActiveProgrammeId(isCurrentlyActive ? null : programmeId);
			if (activeProgrammeId !== programmeId) {
				setActiveCategories({ [programmeId]: true });
				setBreadcrumbs([{ name: programmeName, id: programmeId }]);
			} else {
				setActiveCategories({});
				setBreadcrumbs([]);
			}
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}, 50);
		},
		[activeProgrammeId, setActiveCategories, setBreadcrumbs],
	);

	const handleBreadcrumbClick = useCallback(
		(crumb: Breadcrumb) => {
			const updatedBreadcrumbs = breadcrumbs.splice(0, breadcrumbs.indexOf(crumb) + 1);
			setBreadcrumbs(updatedBreadcrumbs);
			setActiveDepth(updatedBreadcrumbs.length - 1);
			const newActiveCategories: Record<string, boolean> = {};
			updatedBreadcrumbs.forEach((crumb) => {
				if (crumb.id) {
					newActiveCategories[crumb.id] = true;
				}
			});
			setActiveCategories(newActiveCategories);
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}, 50);
		},
		[breadcrumbs, setActiveCategories, setBreadcrumbs],
	);

	const onActiveDepthChanged = useCallback((depth: number) => {
		setActiveDepth(depth);
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, 50);
	}, []);

	const onBackClicked = useCallback(() => {
		if (breadcrumbs.length >= 2) {
			const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2];
			handleBreadcrumbClick(previousBreadcrumb);
		} else if (breadcrumbs.length === 1) {
			resetToInitialState();
		}
	}, [breadcrumbs, handleBreadcrumbClick, resetToInitialState]);

	return (
		<>
			<header>
				<h2 className="header-title">Browse the program</h2>
				{breadcrumbs.length ? (
					<div className="breadcrumbs-container">
						<span className="breadcrumb-subtitle">looking at articles and topics in</span>
						{breadcrumbs.map((crumb, index) => (
							<a key={index} onClick={() => handleBreadcrumbClick(crumb)}>
								<IconChevronRight size={20} /> {crumb.name}
							</a>
						))}
					</div>
				) : null}
			</header>
			<div className="topics-container">
				<div className="browse-topics-list">
					{programmes.map((programme) => (
						<button
							key={programme.id}
							onClick={() => toggleProgrammeDropdown(programme.id, programme.name)}
							className={`item-name inline secondary ${
								activeProgrammeId === programme.id ? 'active' : ''
							}`}
						>
							{programme.name}
						</button>
					))}
				</div>
				{activeProgrammeId && (
					<div className="topics-dropdown-content">
						{programmes
							.filter((programme) => programme.id === activeProgrammeId && programme.categories)
							.map((programme) => (
								<CategoryDropdown
									key={programme.id}
									categories={
										programme.categories ? programme.categories.filter((cat) => !cat.parentId) : []
									}
									activeCategories={activeCategories}
									setActiveCategories={setActiveCategories}
									setBreadcrumbs={setBreadcrumbs}
									depth={0}
									breadcrumbs={breadcrumbs}
									activeProgrammeSlug={programme.slug}
									activeProgrammeId={activeProgrammeId}
									activeDepth={activeDepth}
									onActiveDepthChanged={onActiveDepthChanged}
									onBackClicked={onBackClicked}
								/>
							))}
					</div>
				)}
			</div>
		</>
	);
}
