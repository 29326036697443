/* eslint-disable react/no-unescaped-entities */
'use client';
import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import type { Category as PrismaCategory } from '@dr-pam/common-types/database';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import Loading from '@dr-pam/common-components/Components/Misc/Loading';
import { IconChevronLeft } from '@tabler/icons-react';

export type Breadcrumb = {
	name: string;
	slug?: string;
	id?: string;
};

type ExtendedCategory = PrismaCategory & {
	subCategories?: ExtendedCategory[];
};

type CategoryDropdownProps = {
	categories: ExtendedCategory[];
	depth?: number;
	activeCategories: Record<string, boolean>;
	setActiveCategories: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
	setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
	breadcrumbs: Breadcrumb[];
	activeProgrammeSlug: string;
	activeProgrammeId: string;
	activeDepth: number;
	onActiveDepthChanged: (depth: number) => void;
	onBackClicked: () => void;
};

export default function CategoryDropdown(props: CategoryDropdownProps) {
	const {
		categories,
		depth = 0,
		activeCategories,
		setActiveCategories,
		setBreadcrumbs,
		breadcrumbs,
		activeProgrammeSlug,
		activeProgrammeId,
		activeDepth,
		onActiveDepthChanged,
		onBackClicked,
	} = props;

	const router = useRouter();
	const { addLoader, hasLoader } = useLoadTracker();

	const getCategoryLink = useCallback(
		(category: ExtendedCategory) => {
			return `/${activeProgrammeSlug}/${category.slug}`;
		},
		[activeProgrammeSlug],
	);

	const sortedCategories = useMemo(() => {
		return [...categories].sort((a, b) => a.sortOrder - b.sortOrder);
	}, [categories]);

	const handleCategoryClick = useCallback(
		(
			categoryId: string,
			categorySlug: string,
			categoryName: string,
			currentDepth: number,
			event: React.MouseEvent,
		) => {
			event.stopPropagation();
			event.preventDefault();

			const isActive = !!activeCategories[categoryId];

			const category = categories.find((cat) => cat.id === categoryId);

			if (hasSubcategories(category)) {
				onActiveDepthChanged(currentDepth);
				setBreadcrumbs((prevBreadcrumbs) => {
					if (isActive) {
						return prevBreadcrumbs.slice(0, currentDepth);
					} else {
						const newBreadcrumbs = prevBreadcrumbs.slice(0, currentDepth);
						newBreadcrumbs.push({ name: categoryName, slug: categorySlug, id: categoryId });
						return newBreadcrumbs;
					}
				});
				setActiveCategories((prev) => {
					const newState = { ...prev };
					const deactivateSubcategories = (categories: ExtendedCategory[]) => {
						categories.forEach((cat: ExtendedCategory) => {
							newState[cat.id] = false;
							if (cat.subCategories && cat.subCategories.length > 0) {
								deactivateSubcategories(cat.subCategories);
							}
						});
					};

					categories.forEach((cat) => {
						if (cat.id !== categoryId) {
							newState[cat.id] = false;
							if (cat.subCategories && cat.subCategories.length > 0) {
								deactivateSubcategories(cat.subCategories);
							}
						}
					});

					newState[categoryId] = !isActive;
					return newState;
				});
				setTimeout(() => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}, 50);
			} else {
				if (!isActive && category) {
					addLoader(categoryId);
					router.push(getCategoryLink(category));
				}
			}
		},
		[
			activeCategories,
			addLoader,
			categories,
			getCategoryLink,
			onActiveDepthChanged,
			router,
			setActiveCategories,
			setBreadcrumbs,
		],
	);

	const isBehindActiveDropdown = depth < activeDepth;

	return (
		<ul
			className={`dropdown-list ${isBehindActiveDropdown ? 'inactive' : ''}`}
			onClick={(event) => event.stopPropagation()}
		>
			{sortedCategories.map((category) => (
				<li
					key={category.id}
					onClick={(event) =>
						handleCategoryClick(category.id, category.slug, category.name, depth + 1, event)
					}
					className={`item-name ${activeCategories[category.id] ? 'active' : ''}`}
				>
					{hasSubcategories(category) ? (
						category.name
					) : (
						<a className="no-underline" href={getCategoryLink(category)}>
							{category.name}
						</a>
					)}
					{hasLoader(category.id) && <Loading size="small" inline />}
					{activeCategories[category.id] && category.subCategories && category.subCategories.length > 0 && (
						<CategoryDropdown
							categories={category.subCategories}
							depth={depth + 1}
							activeCategories={activeCategories}
							setActiveCategories={setActiveCategories}
							setBreadcrumbs={setBreadcrumbs}
							breadcrumbs={breadcrumbs}
							activeProgrammeSlug={activeProgrammeSlug}
							activeProgrammeId={activeProgrammeId}
							activeDepth={activeDepth}
							onActiveDepthChanged={onActiveDepthChanged}
							onBackClicked={onBackClicked}
						/>
					)}
				</li>
			))}
			<li className="back" onClick={onBackClicked}>
				<IconChevronLeft />
				<span>previous selection</span>
			</li>
		</ul>
	);
}

function hasSubcategories(category: ExtendedCategory | undefined) {
	return category && category.subCategories && category.subCategories.length > 0;
}
